import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "2-set-up-authprovider"
    }}>{`2. Set up AuthProvider`}</h2>
    <p>{`useAuth uses an AuthProvider component to configure the Auth0 client and share state between components. It's using React context with a reducer behind the scenes, but that's an implementation detail.`}</p>
    <p>{`I recommend adding this around your root component. In Gatsby that's done in gatsby-browser.js and gatsby-ssr.js. Yes useAuth is built so it doesn't break server-side rendering. ✌️`}</p>
    <p>{`But of course server-side "you" will always be logged out.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from "react"
import { navigate } from "gatsby"

import { AuthProvider } from "react-use-auth"

export const wrapRootElement = ({ element }) => (
    <AuthProvider
      navigate={navigate}
      auth0_domain="useauth.auth0.com"
      auth0_client_id="GjWNFNOHqlino7lQNjBwEywalaYtbIzh"
    >
      {element}
      </AuthProvider>
)
`}</code></pre>
    <p><inlineCode parentName="p">{`<AuthProvider>`}</inlineCode>{` creates a context, sets up a state reducer, initializes an Auth0 client and so on. Everything you need for authentication to work in your whole app :)`}</p>
    <p>{`The API takes a couple config options:`}</p>
    <p><inlineCode parentName="p">{`navigate`}</inlineCode>{` – your navigation function, used for redirects. I've tested with Gatsby, but anything should work`}</p>
    <p><inlineCode parentName="p">{`auth0_domain`}</inlineCode>{` – from your Auth0 app`}</p>
    <p><inlineCode parentName="p">{`auth0_client_id`}</inlineCode>{` – from your Auth0 app`}</p>
    <p><inlineCode parentName="p">{`auth0_params`}</inlineCode>{` – an object that lets you overwrite any of the default Auth0 client parameters`}</p>
    <p>{`PS: even though Auth doesn't do anything server-side, useAuth will throw errors during build, if its context doesn't exist`}</p>
    <h3 {...{
      "id": "default-auth0-params"
    }}>{`Default Auth0 params`}</h3>
    <p>{`By default `}<inlineCode parentName="p">{`useAuth`}</inlineCode>{`'s Auth0 client uses these params:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const params = {
    domain: auth0_domain,
    clientID: auth0_client_id,
    redirectUri: \`\${callback_domain}/auth0_callback\`,
    audience: \`https://\${auth0_domain}/api/v2/\`,
    responseType: "token id_token",
    scope: "openid profile email"
}
`}</code></pre>
    <p><inlineCode parentName="p">{`domain`}</inlineCode>{` and `}<inlineCode parentName="p">{`clientID`}</inlineCode>{` come from your props.`}</p>
    <p><inlineCode parentName="p">{`redirectUri`}</inlineCode>{` is set to use the `}<inlineCode parentName="p">{`auth0_callbackpage`}</inlineCode>{` on the current domain. Auth0 redirects here after users login so you can set cookies and stuff. useAuthwill handle this for you ✌️`}</p>
    <p><inlineCode parentName="p">{`audience`}</inlineCode>{` is set to use api/v2. I know this is necessary but honestly have been copypasting it through several of my projects.`}</p>
    <p><inlineCode parentName="p">{`responseType`}</inlineCode>{` same here. I copy paste this from old projects so I figured it's a good default.`}</p>
    <p><inlineCode parentName="p">{`scope`}</inlineCode>{` you need openid for social logins and to be able to fetch user profiles after authentication. Profile and Email too. You can add more via the `}<inlineCode parentName="p">{`auth0_params`}</inlineCode>{` override.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      